/* eslint-disable func-names */
/* eslint-disable prefer-promise-reject-errors */
import React, { createContext, useEffect, useReducer } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import Cookies from "js-cookie";
import BrowserCommunicator from "src/services/browserCommunicator";
import { userEndpoint, vendorEndpoint, ClientToken } from "../mrkt365config";

const initialAuthState = {
    isAuthenticated: false,
    isInitialized: false,
    user: null,
    basic_info: null,
    onBoardingCompleted: false,
};

const setSession = (accessToken) => {
    if (accessToken) {
        // localStorage.setItem('accessToken', accessToken);
        Cookies.set("accessToken", accessToken);
        axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    } else {
        // localStorage.removeItem('accessToken');
        Cookies.remove("accessToken");
        delete axios.defaults.headers.common.Authorization;
    }
};

// function to refactor the user payload values to our req formats.
const refactorUser = (_user) => {
    if (_user === null) return null;
    var temp_user = { ..._user };
    if (temp_user.walkthroughs_completed === null) temp_user.walkthroughs_completed = [];
    return temp_user;
};

const handlers = {
    INITIALIZE: (state, action) => {
        const { isAuthenticated, user, basic_info, onBoardingCompleted } = action.payload;
        return {
            ...state,
            isAuthenticated,
            isInitialized: true,
            user: refactorUser(user),
            basic_info,
            onBoardingCompleted: onBoardingCompleted,
        };
    },
    MRKT365LOGIN: (state, action) => {
        const { user, basic_info, onBoardingCompleted } = action.payload;
        return {
            ...state,
            isAuthenticated: true,
            user: refactorUser(user),
            basic_info,
            onBoardingCompleted: onBoardingCompleted,
        };
    },
    LOGOUT: (state) => ({
        ...state,
        isAuthenticated: false,
        user: null,
        onBoardingCompleted: false,
    }),
    REGISTER: (state, action) => {
        const { user } = action.payload;
        return {
            ...state,
            isAuthenticated: true,
            user: refactorUser(user),
        };
    },
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
    ...initialAuthState,
    method: "JWT",
    initialize: () => Promise.resolve(),
    mrkt365login: () => Promise.resolve(),
    login: () => Promise.resolve(),
    logout: () => Promise.resolve(),
    register: () => Promise.resolve(),
    updateWalkthroghsCompleted: () => Promise.resolve(),
    setBHSWalkthroughCompleted: () => Promise.resolve(),
    registerViaGoogleInitialize: () => Promise.resolve(),
    registerGoogleFinalize: () => Promise.resolve(),
    googleInitialize: () => Promise.resolve(),
    googleFinalize: () => Promise.resolve(),
    registerViaLinkedInInitialize: () => Promise.resolve(),
    registerLinkedInFinalize: () => Promise.resolve(),
    linkedinInitialize: () => Promise.resolve(),
    linkedinFinalize: () => Promise.resolve(),
    getBasicInfo: () => Promise.resolve(),
});

export const AuthProvider = (props) => {
    const { children } = props;
    const [state, dispatch] = useReducer(reducer, initialAuthState);
    const getOnboardingCompleted = (user, completedUpto) => {
        return user.user_type === 1 || user.user_type === 4 || user.user_type === 5 ? true : completedUpto >= 7;
    };

    const dispatchUnAuthUser = () => {
        dispatch({
            type: "INITIALIZE",
            payload: {
                isAuthenticated: false,
                user: null,
                basic_info: null,
                onBoardingCompleted: false,
            },
        });
    };

    const logout = async (refresh = false) => {
        setSession(null);
        BrowserCommunicator.broadcastLogout();
        dispatch({ type: "LOGOUT" });
        if (refresh) setTimeout(() => window.location.reload(), 2000)
    };

    const getBasicInfo = async () => {
        var user;
        await axios
            .get(vendorEndpoint + "get_basic_info")
            .then((res) => {
                var completedUpto = Math.max(...res.data.vendor.completed_step);
                var basic_info = res.data.vendor;
                user = res.data.user;
                Cookies.set("email", user.email);
                dispatch({
                    type: "INITIALIZE",
                    payload: {
                        isAuthenticated: true,
                        user,
                        basic_info,
                        onBoardingCompleted: getOnboardingCompleted(user, completedUpto),
                    },
                });
                return res;
            })
            .catch((err) => {
                if (err.response.status === 403) {
                    // unverified case
                    dispatch({
                        type: "INITIALIZE",
                        payload: {
                            isAuthenticated: true,
                        },
                    });
                } else {
                    // verified but unknown error occured case
                    logout(true);
                }
            });
    };

    const saveFavProfiles = async () => {
        let savedProfileIds = Cookies.get("favourite_user_ids");
        if (!savedProfileIds) return;
        savedProfileIds = JSON.parse(savedProfileIds);
        if (savedProfileIds && savedProfileIds.length) {
            await axios.post(vendorEndpoint + "save_profiles/" + savedProfileIds.join(), {}).then((res) => {

            });
            Cookies.remove("favourite_user_ids");
        }
    };

    const handleAccessTokenAquired = async (accessToken) => {
        setSession(accessToken);
        const basicInfoPromise = getBasicInfo();
        const saveFavProfilesPromise = saveFavProfiles()
        await Promise.all([basicInfoPromise, saveFavProfilesPromise])
    };

    const initialize = async () => {
        try {
            // const accessToken = window.localStorage.getItem('accessToken');
            const accessToken = Cookies.get("accessToken");

            if (accessToken) {
                await handleAccessTokenAquired(accessToken);
            } else {
                dispatch({
                    type: "INITIALIZE",
                    payload: {
                        isAuthenticated: false,
                        user: null,
                    },
                });
            }
        } catch (err) {
            dispatchUnAuthUser();
        }
    };

    useEffect(() => {
        initialize();
    }, []);

    const mrkt365login = async (username, password) => {
        setSession(0);
        var requestOptions = {
            method: "POST",
            headers: {
                accept: "application/json",
                "Content-Type": "application/x-www-form-urlencoded",
                "client-token": ClientToken,
            },
            body: `username=${username}&password=${password}`,
        };
        var loginFailed = false;
        try {
            await fetch(userEndpoint + "auth/jwt/login", requestOptions)
                .then((res) => res.json())
                .then(
                    async (res) => {
                        if (res.detail === "LOGIN_BAD_CREDENTIALS") {
                            loginFailed = true;
                        } else await handleAccessTokenAquired(res.access_token);
                        Cookies.set("email", username);
                    },
                    (error) => {

                    }
                );
            if (loginFailed) dispatchUnAuthUser();
            else BrowserCommunicator.broadcastLogin();
        } catch (e) {
            dispatchUnAuthUser();

        }

        return loginFailed;
    };

    const registerViaGoogleInitialize = async () => {
        setSession(0);
        var requestOptions = {
            method: "GET",
            headers: {
                accept: "application/json",
                "client-token": ClientToken,
            },
        };
        try {
            await fetch(
                userEndpoint +
                "auth/google/authorize?authentication_backend=jwt&scopes=email&scopes=profile&action=register",
                requestOptions
            )
                .then((res) => res.json())
                .then(
                    (res) => {
                        var provided_authorization_url = res["authorization_url"];

                        window.open(provided_authorization_url, "_self");
                    },
                    (error) => {

                    }
                );
        } catch (e) {

        }
    };

    const registerGoogleFinalize = async (query) => {
        var loginFailed = false;
        setSession(0);
        await axios
            .get(userEndpoint + "auth/google/callback" + query + "&action=register")
            .then(async (res) => {
                if (res.data.detail === "LOGIN_BAD_CREDENTIALS") {
                    loginFailed = true;
                    dispatchUnAuthUser();
                } else {
                    await handleAccessTokenAquired(res.data.access_token);
                }
            })
            .catch((error) => {

                return Promise.reject(error);
            });
        return loginFailed;
    };

    const googleInitialize = async (setThirdPartySignInWindow = null) => {
        setSession(0);
        var requestOptions = {
            method: "GET",
            headers: {
                accept: "application/json",
                "Content-Type": "application/x-www-form-urlencoded",
                "client-token": ClientToken,
            },
        };
        var googleSignInWindow;
        try {
            await fetch(
                userEndpoint +
                "auth/google/authorize?authentication_backend=jwt&scopes=email&scopes=profile&action=login",
                requestOptions
            )
                .then((res) => res.json())
                .then(
                    (res) => {
                        var provided_authorization_url = res["authorization_url"];

                        googleSignInWindow = window.open(provided_authorization_url, "", "width=500,height=500");
                        window.onunload = function () {
                            if (googleSignInWindow && !googleSignInWindow.closed) {
                                googleSignInWindow.close();
                            }
                        };
                        if (setThirdPartySignInWindow) setThirdPartySignInWindow(googleSignInWindow);
                    },
                    (error) => {

                    }
                );
        } catch (e) {

        }
    };

    const googleFinalize = async (query) => {
        setSession(0);
        await axios
            .get(userEndpoint + "auth/google/callback" + query + "&action=login")
            .then(async (res) => {
                if (res.data.access_token) await handleAccessTokenAquired(res.data.access_token);
                else return Promise.reject(res.data.detail);
            })
            .catch((error) => {

                return Promise.reject("Google authentication failed");
            });
        await saveFavProfiles();
    };

    const registerViaLinkedInInitialize = async () => {
        setSession(0);
        var requestOptions = {
            method: "GET",
            headers: {
                accept: "application/json",
                "client-token": ClientToken,
            },
        };
        try {
            await fetch(
                userEndpoint +
                "auth/linkedin/authorize?authentication_backend=jwt&scopes=r_basicprofile&scopes=r_emailaddress&action=register",
                requestOptions
            )
                .then((res) => res.json())
                .then(
                    (res) => {
                        var provided_authorization_url = res["authorization_url"];

                        window.open(provided_authorization_url, "_self");
                    },
                    (error) => {
      
                    }
                );
        } catch (e) {

        }
    };

    const registerLinkedInFinalize = async (query) => {
        var loginFailed = false;
        setSession(0);
        await axios
            .get(userEndpoint + "auth/linkedin/callback" + query + "&action=register")
            .then(async (res) => {
                if (res.data.detail === "LOGIN_BAD_CREDENTIALS") {
                    loginFailed = true;
                    dispatchUnAuthUser();
                } else {
                    await handleAccessTokenAquired(res.data.access_token);
                }
            })
            .catch((error) => {

                return Promise.reject(error);
            });
        return loginFailed;
    };

    const linkedinInitialize = async (setThirdPartySignInWindow = null) => {
        setSession(0);
        var requestOptions = {
            method: "GET",
            headers: {
                accept: "application/json",
                "Content-Type": "application/x-www-form-urlencoded",
                "client-token": ClientToken,
            },
        };
        var linkedinSignInWindow;
        try {
            await fetch(
                userEndpoint +
                "auth/linkedin/authorize?authentication_backend=jwt&scopes=r_emailaddress&scopes=r_basicprofile&action=login",
                requestOptions
            )
                .then((res) => res.json())
                .then(
                    (res) => {
                        var provided_authorization_url = res["authorization_url"];

                        linkedinSignInWindow = window.open(provided_authorization_url, "", "width=500,height=500");
                        window.onunload = function () {
                            if (linkedinSignInWindow && !linkedinSignInWindow.closed) {
                                linkedinSignInWindow.close();
                            }
                        };
                        if (setThirdPartySignInWindow) setThirdPartySignInWindow(linkedinSignInWindow);
                    },
                    (error) => {
              
                    }
                );
        } catch (e) {

        }
    };

    const linkedinFinalize = async (query) => {
        setSession(0);
        await axios
            .get(userEndpoint + "auth/linkedin/callback" + query + "&action=login")
            .then(async (res) => {
                if (res.data.access_token) await handleAccessTokenAquired(res.data.access_token);
                else return Promise.reject(res.data.detail);
            })
            .catch((error) => {
                return Promise.reject("LinkedIn authentication failed");
            });
        await saveFavProfiles();
    };

    const updateWalkthroghsCompleted = (walkthroughName) => {
        if (!state.isAuthenticated) return;
        var temp_user = { ...state.user };
        temp_user.walkthroughs_completed.push(walkthroughName);
        dispatch({
            type: "INITIALIZE",
            payload: {
                ...state,
                user: temp_user,
            },
        });
    };

    const setBHSWalkthroughCompleted = () => {
        if (!state.isAuthenticated) return;
        var temp_user = { ...state.user };
        temp_user.walkthroughs_completed = ["bhs_marketplace", "bhs_profile", "bhs_my_projects"];
        var temp_state = { ...state };
        temp_state.user = temp_user;
        dispatch({
            type: "INITIALIZE",
            payload: temp_state,
        });
    };

    return (
        <AuthContext.Provider
            value={{
                ...state,
                method: "JWT",
                initialize,
                mrkt365login,
                logout,
                updateWalkthroghsCompleted,
                setBHSWalkthroughCompleted,
                registerViaGoogleInitialize,
                registerGoogleFinalize,
                googleInitialize,
                googleFinalize,
                registerViaLinkedInInitialize,
                registerLinkedInFinalize,
                linkedinInitialize,
                linkedinFinalize,
                getBasicInfo,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

AuthProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export default AuthContext;
