import React from "react";
import { useEffect } from 'react';
import { useRoutes, useLocation } from "react-router-dom";
import router from "src/router";
import { SnackbarProvider } from "notistack";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import useAuth from "src/hooks/useAuth";
import { CssBaseline } from "@mui/material";
import ThemeProvider from "./theme/ThemeProvider";
import AppInit from "./components/AppInit";
import { AppContextProvider } from "./contexts/AppContext";
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';


const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: '270cbeb3-6f72-46b8-9f13-428ee6c43aca',
    extensions: [reactPlugin],
  },
});

appInsights.loadAppInsights();

function App() {
    const content = useRoutes(router);
    const auth = useAuth();
    const location = useLocation();

    useEffect(() => {
        reactPlugin.trackPageView({ name: location.pathname });
      }, [location]);

    return (
        <ThemeProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <SnackbarProvider
                    maxSnack={6}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                    }}
                >
                    <AppContextProvider>
                        <CssBaseline />
                        {auth.isInitialized ? content : <AppInit />}
                    </AppContextProvider>
                </SnackbarProvider>
            </LocalizationProvider>
        </ThemeProvider>
    );
}
export default App;
