/* eslint-disable func-names */
import { Dialog, Box, Grid, styled, Slide, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React, { forwardRef, useState, useEffect, useContext } from 'react';
import LoginJWT from 'src/content/pages/Auth/Login/LoginJWT';
import AppContext from 'src/contexts/AppContext';

const DialogWrapper = styled(Dialog)(
	() => `
        .MuiDialog-paper {
          overflow: auto;
        }
  `
);

const Transition = forwardRef((props, ref) => {
	return <Slide direction="down" ref={ref} {...props} />;
});

const LoginPopUp = ({ showLogInPopUP, setShowLogInPopUP }) => {
	const [registerPopUp, setRegisterPopUp] = useState(null);
	const [thirdPartySignInWindow, setThirdPartySignInWindow] = useState(null);
	const { extraData } = useContext(AppContext);

	const closeThirdPartySignInWindow = () => {
		if (thirdPartySignInWindow && !thirdPartySignInWindow.closed) {
			thirdPartySignInWindow.close();
		}
	};

	const closeRegisterWindow = () => {
		if (registerPopUp && !registerPopUp.closed) {
			registerPopUp.close();
		}
	};

	const closeAllOpenedChildWindows = () => {
		closeThirdPartySignInWindow();
		closeRegisterWindow();
	};
	const handleSignup = () => {
		closeAllOpenedChildWindows();
		let registerAsParam = '?';
		if (extraData.registerAs) registerAsParam = '?register_as=' + extraData.registerAs + '&';
		setRegisterPopUp(
			window.open(
				`/register${registerAsParam}pop-up-register=true&next=` +
					window.location.pathname +
					encodeURIComponent(window.location.search),
				'',
				'width=500,height=500'
			)
		);
		window.onunload = function () {
			if (registerPopUp && !registerPopUp.closed) {
				registerPopUp.close();
			}
		};
	};

	const handleClose = () => {
		setShowLogInPopUP(false);
	};

	useEffect(
		() => () => {
			if (registerPopUp && !registerPopUp.closed) {
				registerPopUp.close();
			}
		},
		[]
	);

	return (
		<DialogWrapper
			open={showLogInPopUP}
			maxWidth="md"
			fullWidth
			TransitionComponent={Transition}
			keepMounted
			onClose={handleClose}
		>
			<IconButton
				aria-label="close"
				onClick={handleClose}
				style={{
					position: 'absolute',
					right: 8,
					top: 8,
				}}
			>
				<CloseIcon />
			</IconButton>
			<div className="wrapQuickSec">
				<Box display="flex" alignItems="center" justifyContent="space-between" sx={{ mt: 0.5 }}>
					<Grid
						className="wrapQuickDemo"
						sx={{
							margin: 'auto !important',
							width: '96% !important',
							'@media screen and (max-width: 767px)': { display: 'block' },
						}}
						container
						spacing={5}
					>
						<Grid
							className="quickDemoLeft"
							sx={{ '@media screen and (max-width: 767px)': { padding: '10px 0px !important' } }}
							item
							md={6}
						>
							<img
								src="https://mrkt365prodcdnsaendpoint.azureedge.net/mrkt365v2/static/demo/images/quick-demo-bss-walkthrough.png"
								alt="Login"
							></img>

							<Typography
								sx={{
									fontSize: '20px',
									lineHeight: '40px',
									fontWeight: '400',
								}}
								variant="subtitle2"
							>
								Please <span style={{ color: '#FF1341' }}>Sign in / Sign up</span> to continue
							</Typography>
						</Grid>
						<Grid
							className="quickDemoRight"
							sx={{ '@media screen and (max-width: 767px)': { padding: '10px !important' } }}
							item
							md={6}
						>
							<LoginJWT
								isSubComponent
								setThirdPartySignInWindow={setThirdPartySignInWindow}
								closeAllOpenedChildWindows={closeAllOpenedChildWindows}
							/>
							<p>
								Don’t have an account, yet?{' '}
								<a
									href={() => false}
									onClick={handleSignup}
									style={{ color: 'blue', cursor: 'pointer', textDecoration: 'underline' }}
								>
									Sign up
								</a>
							</p>
						</Grid>
					</Grid>
				</Box>
			</div>
		</DialogWrapper>
	);
};

export default LoginPopUp;
