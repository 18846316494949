import React, { lazy } from 'react';
import Authenticated from 'src/components/Authenticated';
import CollapsedSidebarLayout from 'src/layouts/CollapsedSidebarLayout';
import Loader from 'src/components/Loader';
import { UserTypeLabels } from 'src/constants';

const ProjectPreviewScreen = Loader(lazy(() => import('src/content/project/project-preview')));
const TaskView = Loader(lazy(() => import('src/content/project/taskView')));

const ProjectListing = Loader(lazy(() => import('src/content/project/projectListing')));

const MyProjects = Loader(lazy(() => import('src/content/project/projectListing/myProjects')));

const TaskDashboard = Loader(lazy(() => import('src/content/task-dashboard')));

const ProjectTaskRoutes = [
	{
		path: 'project-preview',
		element: (
			<Authenticated>
				<CollapsedSidebarLayout />
			</Authenticated>
		),
		children: [
			{
				path: ':type/:projectId',
				element: <ProjectPreviewScreen />,
			},
		],
	},
	{
		path: 'assign-task',
		element: (
			<Authenticated>
				<CollapsedSidebarLayout />
			</Authenticated>
		),
		children: [
			{
				path: ':taskId',
				element: <TaskView type="creator" />,
			},
		],
	},
	{
		path: 'task-invite',
		element: (
			<Authenticated>
				<CollapsedSidebarLayout />
			</Authenticated>
		),
		children: [
			{
				path: ':taskId',
				element: <TaskView type="assignee" />,
			},
		],
	},
	{
		path: 'project-listing',
		element: (
			<Authenticated>
				<CollapsedSidebarLayout />
			</Authenticated>
		),
		children: [
			{
				path: '',
				element: <ProjectListing />,
			},
		],
	},
	{
		path: 'task-dashboard',
		element: (
			<Authenticated>
				<CollapsedSidebarLayout />
			</Authenticated>
		),
		children: [
			{
				path: ':type/:taskId',
				element: <TaskDashboard />,
			},
		],
	},
	{
		path: 'my-projects',
		element: (
			<Authenticated onlyAllow={[UserTypeLabels.BUSINESS, UserTypeLabels.BUSINESS_MANAGER]}>
				<CollapsedSidebarLayout />
			</Authenticated>
		),
		children: [
			{
				path: '',
				// element: <CompanyProjectsDetailedView />,
				element: <MyProjects />,
			},
		],
	},
];

export default ProjectTaskRoutes;
